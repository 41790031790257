import React, { useContext } from "react"
import { Button, Container, Navbar } from "react-bootstrap"
import IconLogo from "../../../assets/img/logo.svg"
import IconUser from "../../../assets/img/user.svg"
import { route } from "../../constants/route"
import HeaderNav from "./headerNav/HeaderNav"
import OffcanvasUi from "./offCanvas/OffcanvasUi"
import { Link, navigate } from "gatsby"
import { AppContext } from "../../context/AppContext"
import ProFileIcon from "../../../assets/img/profile.svg"
import LogoutIcon from "../../../assets/img/logout.svg"
import useClient from "../../hook/useClient"
import * as style from "./Header.module.scss"

export default function Header(props) {
  const { logout } = useContext(AppContext)
  let pathname = null
  let profileInfo = null
  const isClient = useClient()
  if (isClient) {
    pathname = window.location.pathname
    profileInfo = localStorage.getItem("profileInfo")
      ? JSON.parse(localStorage.getItem("profileInfo"))
      : null
  }
  const router = navigate
  const isAuth = !!profileInfo
  const isHidden = pathname !== null && pathname.includes("auth")

  return (
    <>
      <Navbar
        expand="lg"
        variant={isAuth ? "light" : "dark"}
        bg={isAuth ? "light" : "dark"}
        sticky="top"
        className={`py-4 ${isHidden ? "layout_hidden" : ""} `}
      >
        <Container
          fluid={"xxl"}
          className={`${isAuth ? `${style.authNav}` : ""}`}
        >
          <Link to={"/"} className={"d-flex align-items-center nav-link"}>
            <IconLogo />
            <span
              className={`mx-2 ${
                isAuth ? "text-dark d-sm-block d-none" : "text-white"
              } fs-5 text-center  fw-bold`}
              style={{ marginBottom: "5px" }}
            >
              адаптер
            </span>
          </Link>
          <HeaderNav isOffCanvas={false} isAuth={isAuth} />
          <div className={"d-flex"}>
            {isAuth ? (
              <div className={"d-flex align-items-center gap-2"}>
                <Link
                  className={
                    "btn btn-link p-0 text-dark text-decoration-none text-capitalize d-flex align-items-center"
                  }
                  to={route.profile}
                >
                  <span
                    className={"d-none d-sm-block"}
                    style={{ marginRight: "8px" }}
                  >
                    {profileInfo?.name ? profileInfo.name : "guest"}
                  </span>
                  <ProFileIcon />
                </Link>

                <Button
                  variant={"link"}
                  className={"btn btn-link p-0"}
                  onClick={() => {
                    logout()
                  }}
                >
                  <LogoutIcon />
                </Button>
              </div>
            ) : (
              <div className={"d-none gap-2 d-sm-flex"}>
                <Button
                  variant={"light"}
                  className={"btn-custom fw-bold"}
                  onClick={() => router(route.tariff)}
                >
                  попробовать бесплатно
                </Button>
                <Button
                  variant={"primary"}
                  className={
                    "d-flex align-items-center gap-2  btn-custom fw-bold"
                  }
                  onClick={() => {
                    navigate(route.login)
                  }}
                >
                  <IconUser />
                  войти
                </Button>
              </div>
            )}
            {!isAuth && <OffcanvasUi router={router} />}
          </div>
        </Container>
      </Navbar>
    </>
  )
}
